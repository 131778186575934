var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overflow-auto"},[_c('pro-document-breadcrumbs',{attrs:{"breadcrumbs":[
      {
        name: 'menu.ProcurerManagement',
        icon: 'PROSmart-AccountEdit',
        to: { name: 'ProcurerManagementList' },
      },
      { name: 'menu.EditProcurerInfo' } ],"user-role":"procurer"}}),_c('div',{staticClass:"q-pa-md"},[_c('pro-form-render',{attrs:{"mode":"Edit","code":"P_UpdateProcurerInfo","form-data-promise":_vm.formDataPromise()},on:{"pro-form-submit":function () { return this$1.$router.push({ name: 'ProcurerManagementList' }); }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }